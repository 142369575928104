body {
  margin: 0;
  font-family: "Inter" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #111827;
}

code {
  font-family: "Inter" !important;
  color: #111827;
}
.primary-btn {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
  color: white;
  /* align-items: center; */
  background-color: #7454f2;
  /* text-transform: uppercase; */
}

.calulaterImg {
  background-image: url("./assets/images/bg1.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  /* scale: 0.5; */
}

/* CSS for scrollbar */
::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; /* color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 5px;
  background: transparent; /* color of the thumb */
  border-radius: 1px; /* roundness of the thumb */
}

.flip-card {
  background-color: transparent;
  width: 230px;
  height: 250px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(-180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #6c19f30d;
  padding: 20px;
  /* color: ; */
}

.flip-card-back {
  background-color: #ffff;
  padding: 20px;
  /* color: white; */
  transform: rotateY(-180deg);
}
@tailwind base;
@tailwind components;
@tailwind utilities;
